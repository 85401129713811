import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'

type TProps = {
  className?: string
} & PropsWithChildren

const Card: FC<TProps> = ({ children, className }) => {
  return (
    <div
      className={cn('mx-auto flex w-full flex-col rounded-2.5 p-6 max-xl:max-w-[calc(35rem)]', className)}
      style={{
        background: 'linear-gradient(0deg, rgba(223, 229, 242, 0.10) 0%, rgba(223, 229, 242, 0.10) 100%)',
      }}
    >
      {children}
    </div>
  )
}

export { Card }
