import { FC } from 'react'

import { ApiBlock } from './components/ApiBlock/ApiBlock'
import { BenefitsBlock } from './components/BenefitsBlock/BenefitsBlock'
import { ChallengesBlock } from './components/ChallengesBlock/ChallengesBlock'
import { EcosystemBlock } from './components/EcosystemBlock/EcosystemBlock'
import { JoinBlock } from './components/JoinBlock/JoinBlock'
import { LoselessBlock } from './components/LoselessBlock/LoselessBlock'
import { MainBlock } from './components/MainBlock/MainBlock'
import { PartnersBlock } from './components/PartnersBlock/PartnersBlock'
import { ProcessorsBlock } from './components/ProcessorsBlock/ProcessorsBlock'
import { ResultBlock } from './components/ResultBlock/ResultBlock'
import { SliderBlock } from './components/SliderBlock/SliderBlock'
import { WelcomeBlock } from './components/WelcomeBlock/WelcomeBlock'

type TProps = {}

const HomePageContent: FC<TProps> = () => {
  return (
    <div className="flex w-full flex-col">
      <MainBlock />
      <WelcomeBlock />
      <EcosystemBlock />
      <LoselessBlock />
      <SliderBlock />
      <ChallengesBlock />
      <ResultBlock />
      <BenefitsBlock />
      <ProcessorsBlock />
      <ApiBlock />
      <PartnersBlock />
      <JoinBlock />
    </div>
  )
}

export { HomePageContent }
