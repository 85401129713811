import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { BgWrapper, WrapperType } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import Slide1 from 'images/home/slides/1.jpg'
import Slide2 from 'images/home/slides/2.jpg'
import Slide3 from 'images/home/slides/3.jpg'
import Slide4 from 'images/home/slides/4.jpg'
import Slide5 from 'images/home/slides/5.jpg'
import Slide6 from 'images/home/slides/6.jpg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Slide } from './components/Slide/Slide'

type TProps = {}

const SLIDES = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide6]

const SliderBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)
  const ref = useRef<HTMLDivElement>(null)
  const [offsetLeft, setOffsetLeft] = useState<number>(0)

  useEffect(() => {
    if (ref.current && !offsetLeft) {
      setOffsetLeft(ref.current.offsetLeft - 20)
    }
  }, [ref, offsetLeft])

  return (
    <BgWrapper type={WrapperType.LIGHT}>
      <ResponsiveBlock>
        <div className="flex w-full flex-col items-center gap-40 py-40 max-1.5xl:py-20 max-xl:gap-20 1.5xl:min-h-screen">
          <Text
            tagName="h2"
            className="font-title---100px text-center uppercase text-palette-black max-1.5xl:font-title---70px max-lg:font-title---50px"
          >
            {t('slider_block.title')}
          </Text>
          <div ref={ref} className="flex w-full">
            <ul className="flex h-full w-full flex-col gap-40 max-xl:gap-20">
              {SLIDES.map((item, index) => {
                return <Slide key={index} index={index} image={item} count={SLIDES.length} />
              })}
            </ul>
          </div>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { SliderBlock }
