import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { BgWrapper } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import { useClaimController } from 'client/controllers/claimController'
import { Logo } from 'components/Logo/Logo'
import SkewLeft from 'images/home/skew_left.png'
import SkewRight from 'images/home/skew_right.png'
import Arrow from 'images/icons/arrow2.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const JoinBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)
  const { setIsOpen } = useClaimController()

  return (
    <BgWrapper>
      <ResponsiveBlock>
        <div className="flex w-full flex-col py-40 max-lg:py-8">
          <div className="relative flex w-full flex-col items-center gap-10 whitespace-pre-wrap rounded-2.5 border border-solid border-palette-grey--30 py-30 text-center text-palette-grey max-lg:gap-8 max-lg:p-8">
            <div className="flex items-center gap-2">
              <Text tagName="span" className="font-body---20px uppercase">
                {t(`join_block.subtitle`)}
              </Text>
              <Logo className="h-3.5" />
            </div>
            <Text
              tagName="span"
              className="font-title---100px uppercase max-1.5xl:font-title---70px max-lg:font-title---50px"
            >
              {t(`join_block.title`)}
            </Text>
            <Text tagName="span" className="font-body---20px">
              {t(`join_block.caption`)}
            </Text>
            <div className="flex cursor-pointer items-center gap-3" onClick={() => setIsOpen(true)}>
              <Text tagName="span" className="font-body---20px uppercase">
                {t('join_block.button')}
              </Text>
              <div className="flex items-center text-palette-blue">
                <Arrow className="h-4 w-4" />
                <Arrow className="h-4 w-4 -translate-x-1" />
              </div>
            </div>
            <div className="absolute -bottom-1 left-6 max-big:hidden">
              <img src={SkewLeft} className="" />
            </div>
            <div className="absolute -right-36 -top-12 max-big:hidden">
              <img src={SkewRight} className="" />
            </div>
          </div>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { JoinBlock }
