import { FC } from 'react'
import { Button } from '@blueprintjs/core'
import { Menu } from '@blueprintjs/icons'
import { Link } from '@inertiajs/react'
import { ResponsiveBlock } from 'client/components/ResponsiveBlock/ResponsiveBlock'
import { Logo } from 'components/Logo/Logo'

import { useMenuDrawerController } from '../MenuDrawer/controller/menuDrawerController'

import { HeaderButtons } from './components/HeaderButtons/HeaderButtons'
import { Navigation } from './components/Navigation/Navigation'

type TProps = {}

const Header: FC<TProps> = () => {
  const controller = useMenuDrawerController()

  return (
    <header className="relative z-1 flex h-24 w-full flex-none items-center justify-center bg-surface-primary">
      <ResponsiveBlock size="wide" type="primary">
        <div className="flex w-full items-center justify-between">
          <Link href="/">
            <Logo className="h-6" />
          </Link>
          <div className="hidden h-full items-center gap-10 1.5xl:flex">
            <Navigation />
            <HeaderButtons />
          </div>
        </div>
      </ResponsiveBlock>
      <div className="fixed right-6 top-6 flex 1.5xl:hidden">
        <Button
          className="h-12 w-12 rounded-half !bg-surface-black focus:outline-none"
          icon={<Menu className="!text-text-white" size={20} />}
          minimal
          onClick={controller.open}
        />
      </div>
    </header>
  )
}

export { Header }
