import { FC, useEffect, useState } from 'react'
import Icon from 'images/svg/digital_distribution/telegram.svg'
import { motion, useScroll, useTransform } from 'motion/react'

import { ICON_SIZE, SMALL_ORBIT_COEFF } from '../../constants'

type TProps = { width?: number }

const Telegram: FC<TProps> = ({ width = 0 }) => {
  const [size, setSize] = useState<number>(width)

  useEffect(() => {
    setSize(width * SMALL_ORBIT_COEFF)
  }, [width])

  const { scrollYProgress } = useScroll()
  const pDeg = useTransform(scrollYProgress, [0, 0.07, 0.13, 0.2], [140, 135, 130, 125])
  const cDeg = useTransform(scrollYProgress, [0, 0.07, 0.13, 0.2], [-140, -135, -130, -125])

  const translateX = size / 2 - ICON_SIZE
  const translateY = -(ICON_SIZE / 2)

  return (
    <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center overflow-hidden">
      <motion.div transition={{ delay: 0.3 }} style={{ width: size, height: size, rotate: pDeg }}>
        <motion.div
          className="flex h-16 w-16"
          style={{ rotate: cDeg, translateX, translateY }}
          transition={{ duration: 0.3 }}
        >
          <img
            src={Icon}
            className="rounded-half object-contain"
            style={{ boxShadow: '0px 1px 33.8px 0px rgba(37, 157, 216, 0.75)' }}
          />
        </motion.div>
      </motion.div>
    </div>
  )
}

export { Telegram }
