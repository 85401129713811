import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'
import { BgWrapper, WrapperShadow } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import Arrow from 'images/icons/arrow2.svg?react'
import Globe from 'images/icons/globe.svg'
import Ticket from 'images/icons/ticket.svg'
import Wallet from 'images/icons/wallet.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Card } from './components/Card/Card'

type TProps = {}

const RESULTS = ['-40%', '-85%', '-100%']
const COLUMNS = [
  {
    icon: Wallet,
    iconClassName: ctl(`bg-palette-grey text-text-black`),
    listSize: 2,
  },
  {
    icon: Globe,
    iconClassName: ctl(`bg-palette-yellow text-text-black`),
    listSize: 3,
  },
  {
    icon: Ticket,
    iconClassName: ctl(`bg-palette-blue`),
    listSize: 4,
  },
]

const ResultBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <BgWrapper shadow={WrapperShadow.TOP}>
      <ResponsiveBlock>
        <div className="flex w-full flex-col gap-30 py-40 max-1.5xl:py-20">
          <div className="flex w-full flex-col items-center gap-8">
            <div className="flex flex-col items-center gap-3 text-center">
              <Text
                tagName="h2"
                className="font-title---100px text-center uppercase text-palette-grey max-1.5xl:font-title---70px max-lg:font-title---50px"
              >
                {t('result_block.title')}
              </Text>
              <Text tagName="span" className="font-body---24px mb-8 whitespace-pre-wrap text-center text-palette-grey">
                {t('result_block.caption')}
              </Text>
            </div>
            <div className="grid grid-cols-3 gap-8 max-xl:grid-cols-1 1.5xl:w-full">
              {RESULTS.map((result, index) => {
                const listNumber = index + 1

                return (
                  <Card>
                    <div className="flex w-full flex-col gap-12 py-8 text-center">
                      <Text tagName="span" className="font-title---100px text-palette-grey max-1.5xl:font-title---70px">
                        {result}
                      </Text>
                      <Text tagName="span" className="font-body---20px text-palette-grey--30">
                        {t(`result_block.list.${listNumber}.title`)}
                      </Text>
                    </div>
                  </Card>
                )
              })}
            </div>
          </div>
          <div className="flex w-full flex-col items-center gap-16">
            <div className="flex flex-col items-center text-center">
              <Text
                tagName="h2"
                className="font-title---100px text-center uppercase text-palette-grey max-1.5xl:font-title---70px max-lg:font-title---50px"
              >
                {t('products_block.title')}
              </Text>
            </div>
            <div className="grid grid-cols-3 gap-8 max-xl:grid-cols-1 1.5xl:w-full">
              {COLUMNS.map((item, index) => {
                const columnNumber = index + 1

                return (
                  <Card className="gap-6">
                    <div className="flex w-full flex-col gap-7 border-b border-solid border-palette-grey--30 pb-6">
                      <div
                        className={cn(
                          'flex h-13 w-13 flex-none items-center justify-center rounded-2.5',
                          item.iconClassName,
                        )}
                      >
                        <img src={item.icon} className="h-8 w-8" />
                      </div>
                      <div className="flex flex-col gap-2 uppercase text-palette-grey">
                        <Text tagName="span" className="font-body---14px">
                          {t(`products_block.columns.${columnNumber}.caption`)}
                        </Text>
                        <Text tagName="span" className="font-title---50px whitespace-pre-wrap">
                          {t(`products_block.columns.${columnNumber}.title`)}
                        </Text>
                      </div>
                    </div>
                    <ul className="flex w-full flex-col gap-7">
                      {Array(item.listSize)
                        .fill(null)
                        .map((_, listIndex) => {
                          const listNumber = listIndex + 1

                          return (
                            <li key={listNumber} className="flex w-full flex-col gap-3">
                              <div className="inline w-full text-palette-grey">
                                <Arrow className="mr-3 h-4 w-4" />
                                <Text tagName="span" className="font-body---20px">
                                  {t(`products_block.columns.${columnNumber}.list.${listNumber}.title`)}
                                </Text>
                              </div>
                              <Text tagName="p" className="font-body---16px whitespace-pre-wrap text-palette-grey--70">
                                {t(`products_block.columns.${columnNumber}.list.${listNumber}.caption`)}
                              </Text>
                            </li>
                          )
                        })}
                    </ul>
                  </Card>
                )
              })}
            </div>
          </div>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { ResultBlock }
