import { FC } from 'react'
import { AppHead } from 'client/components/Layout/components/AppHead/AppHead'
import { LayoutNew } from 'client/components/LayoutNew/LayoutNew'
import { HomePageContent } from 'client/contents/HomePageContent/HomePageContent'

type TProps = {}

const HomePage: FC<TProps> = () => {
  return (
    <article className="flex w-full flex-col">
      <AppHead />
      <HomePageContent />
    </article>
  )
}

//  Apply new design only for home page
// @ts-expect-error
HomePage.layout = (page) => <LayoutNew>{page}</LayoutNew>

export default HomePage
