import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { BgWrapper } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import { Trans } from 'client/components/Trans/Trans'
import Alex from 'images/home/alex.svg'
import ArrowDown from 'images/home/arrow_down.svg?react'
import Payout from 'images/home/bank_payout.svg'
import List from 'images/home/loseless_list.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const LoselessBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <BgWrapper>
      <ResponsiveBlock>
        <div className="flex w-full flex-col items-center gap-8 py-40 max-1.5xl:py-20 1.5xl:min-h-screen">
          <Text
            tagName="h2"
            className="font-title---100px text-center uppercase text-palette-white max-1.5xl:font-title---70px max-lg:font-title---50px"
          >
            {t('loseless_block.title')}
          </Text>
          <Text tagName="span" className="font-body---24px mb-8 whitespace-pre-wrap text-center text-palette-grey--50">
            <Trans
              i18nKey="loseless_block.caption"
              ns={ClientLocaleNS.MainPage}
              values={{
                first: 5,
                second: 50,
              }}
            />
          </Text>
          <div className="flex w-full justify-center rounded-xl bg-palette-blue py-10">
            <div className="flex w-100 flex-col px-4">
              <img src={Alex} className="w-full" />
              <div className="flex h-12 w-full items-center justify-center">
                <ScrollAppear>
                  <ArrowDown className="text-text-tertiary" />
                </ScrollAppear>
              </div>
              <img src={List} className="w-full" />
              <div className="flex h-12 w-full items-center justify-center">
                <ScrollAppear delay={0.8}>
                  <ArrowDown className="text-text-tertiary" />
                </ScrollAppear>
              </div>
              <Text tagName="span" className="font-body---16px mb-4 text-center text-palette-grey">
                {t('loseless_block.revenue')}
              </Text>
              <img src={Payout} className="w-full" />
            </div>
          </div>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { LoselessBlock }
