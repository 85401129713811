import { FC, PropsWithChildren, useState } from 'react'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import cn from 'classnames'
import ArrowDown from 'images/icons/down.svg?react'
import { AnimatePresence, motion, Variants } from 'motion/react'

type TProps = { text: string; big?: boolean } & PropsWithChildren

const variants: Variants = {
  hidden: { opacity: 0, transform: 'translateY(18px)' },
  visible: { opacity: 1, transform: 'translateY(0)' },
}

const NavPopover: FC<TProps> = ({ text, big, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <li className="relative flex focus:outline-none" onMouseLeave={() => setIsOpen(false)}>
      <div
        className="flex cursor-pointer select-none items-center gap-1 focus:outline-none"
        onMouseEnter={() => setIsOpen(true)}
      >
        <Text
          tagName="span"
          className={ctl(`font-body---14px flex items-center uppercase text-text-white focus:outline-none`)}
        >
          {text}
        </Text>
        <ArrowDown className={cn('w-4 text-text-white transition-all', isOpen && 'rotate-180')} />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ ease: 'linear', duration: 0.2 }}
            className={cn('absolute top-full flex')}
          >
            <div className="relative flex flex-none flex-col pt-2">
              <div
                className={cn(
                  `absolute  h-0 w-0 rotate-45
              border-8 border-solid border-surface-white
              border-b-transparent border-r-transparent bg-transparent`,
                  big ? 'left-5' : 'left-1/4 -translate-x-1/2',
                )}
                style={{ top: '1px' }}
              />
              <ul
                className={cn(
                  'flex flex-col whitespace-nowrap bg-surface-white shadow-lg',
                  big ? '-translate-x-12 gap-4 rounded-3xl px-8 py-10' : '-translate-x-1/4 rounded-smd py-1',
                )}
              >
                {children}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  )
}

export { NavPopover }

// content: "";
//   position: absolute;
//   height: 0;
//   width: 0;
//   border-left: 8px solid #fff;
//   border-top: 8px solid #fff;
//   border-bottom: 8px solid transparent;
//   border-right: 8px solid transparent;
//   transform: translateX(64px) translateY(3px) rotate(45deg);
//   top: -10px;
//   left: 0;
//   background: none;
// }
