import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { BgWrapper, WrapperType } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import Checkout from 'images/home/checkout_2.svg'
import Arrow from 'images/icons/arrow2.svg?react'
import Bank from 'images/icons/bank.svg'
import Court from 'images/icons/court.svg'
import Compliance from 'images/icons/doc.svg'
import ManageAccounts from 'images/icons/manage_accounts.svg'
import Payment from 'images/icons/payment.svg'
import Antifraud from 'images/icons/security-full.svg'
import Support from 'images/icons/support.svg'
import Taxes from 'images/icons/tax.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Tag } from './components/Tag/Tag'

type TProps = {}

const ITEMS = [
  {
    icon: Payment,
    className: ctl(`bg-palette-purple text-palette-white`),
  },
  {
    icon: Antifraud,
    className: ctl(`bg-palette-red text-palette-black`),
  },
  {
    icon: Taxes,
    className: ctl(`bg-palette-black text-palette-white`),
  },
  {
    icon: Compliance,
    className: ctl(`bg-palette-green text-palette-white`),
  },
  {
    icon: Support,
    className: ctl(`bg-palette-blue text-palette-white`),
  },
]

const COLUMNS = [
  {
    icon: Bank,
    listSize: 4,
  },
  {
    icon: Court,
    listSize: 5,
  },
  {
    icon: ManageAccounts,
    listSize: 4,
  },
]

const ChallengesBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <BgWrapper type={WrapperType.LIGHT}>
      <ResponsiveBlock>
        <div className="flex w-full flex-col items-center gap-20 py-40 max-1.5xl:py-20 max-xl:gap-8 1.5xl:min-h-screen">
          <Text
            tagName="h2"
            className="font-title---100px whitespace-pre-wrap text-center uppercase text-palette-black max-1.5xl:font-title---70px max-lg:font-title---50px"
          >
            {t('challenges_block.title')}
          </Text>
          <div className="flex w-full gap-5 max-xl:flex-col max-xl:items-center max-xl:gap-8">
            <div className="flex h-full max-w-[calc(22.5rem)]">
              <ScrollAppear>
                <img src={Checkout} className="h-full w-full" />
              </ScrollAppear>
            </div>
            <div className="flex flex-1 flex-col gap-5">
              <ul className="flex w-full gap-2.5 max-lg:flex-wrap">
                {ITEMS.map((item, index) => (
                  <Tag
                    key={index}
                    text={t(`challenges_block.list.${index + 1}.title`)}
                    icon={item.icon}
                    className={item.className}
                  />
                ))}
              </ul>
              <div className="flex w-full flex-1 flex-col items-center rounded-2.5 bg-palette-white p-8 text-palette-black">
                <div className="flex flex-col items-center gap-1 max-xl:text-center">
                  <Text tagName="span" className="font-body---32px font-black uppercase tracking-wider">
                    {t(`challenges_block.mor.title`)}
                  </Text>
                  <Text tagName="span" className="font-body---24px tracking-tighter">
                    {t(`challenges_block.mor.caption`)}
                  </Text>
                </div>
                <div className="my-8 h-px w-full bg-palette-grey--70" />
                <div className="grid grid-cols-3 gap-5 max-xl:grid-cols-1">
                  {COLUMNS.map((item, index) => {
                    const columnNumber = index + 1

                    return (
                      <div key={index} className="flex flex-col gap-14">
                        <div className="flex items-center gap-3">
                          <div className="flex h-13 w-13 flex-none items-center justify-center rounded-2.5 bg-palette-black">
                            <img src={item.icon} className="h-8 w-8" />
                          </div>
                          <Text tagName="span" className="font-body---20px whitespace-pre-wrap">
                            {t(`challenges_block.mor.columns.${columnNumber}.title`)}
                          </Text>
                        </div>
                        <ul className="flex w-full flex-col gap-8">
                          {Array(item.listSize)
                            .fill(null)
                            .map((_, listIndex) => {
                              const listNumber = listIndex + 1

                              return (
                                <li key={listNumber} className="flex items-start">
                                  <Arrow className="mr-3 w-4 flex-none translate-y-0.5 text-palette-black" />
                                  <Text tagName="span" className="font-body---16px">
                                    {t(`challenges_block.mor.columns.${columnNumber}.list.${listNumber}.title`)}
                                  </Text>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { ChallengesBlock }
