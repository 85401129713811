import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {
  image: string
  count: number
  index: number
} & PropsWithChildren

const Slide: FC<TProps> = ({ image, count, index }) => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <li className="flex w-full items-center gap-20 max-xl:gap-8">
      <ul className="flex flex-col gap-4">
        {Array(count)
          .fill(null)
          .map((_, dotIndex) => (
            <li key={dotIndex} className="flex h-5 w-5 items-center justify-center p-0.5 max-lg:h-4 max-lg:w-4">
              <div
                className={cn(
                  'flex h-4 w-4 max-lg:h-3 max-lg:w-3',
                  dotIndex === index ? 'rotate-45 bg-palette-blue' : 'bg-palette-black--10',
                )}
              />
            </li>
          ))}
      </ul>
      <div className="flex flex-1 items-center gap-30 max-1.5xl:gap-20 max-xl:flex-col max-xl:gap-8">
        <div className="w-4/12 max-xl:w-full">
          <Text tagName="span" className="font-body---24px text-palette-black">
            {t(`slider_block.list.${index + 1}.title`)}
          </Text>
        </div>
        <div className="flex flex-1">
          <ScrollAppear>
            <img src={image} className="h-full w-full" />
          </ScrollAppear>
        </div>
      </div>
    </li>
  )
}

export { Slide }
