import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import Logo from 'images/svg/logo.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const Footer: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.DemoPage)

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="font-tertiary-regular---8pt flex w-full flex-col justify-center gap-1 text-center text-text-gray">
        <Text tagName="span" className="w-full">
          {t('settings.form.footer.text_one')}
        </Text>
        <div className="flex w-full items-center justify-center gap-1">
          <Text tagName="span" className="">
            {t('settings.form.footer.text_two')}
          </Text>
          <div className="flex h-2 items-center">
            <img src={Logo} className="h-full" />
          </div>
          <a
            href="https://thenumberx.com/eula"
            target="_blank"
            rel="noopener noreferrer"
            className="border-0 border-b-1 border-solid border-palette-purple-alt leading-4 text-text-gray !no-underline hover:text-text-gray"
          >
            <Text tagName="span" className="">
              {t('settings.form.footer.link')}
            </Text>
          </a>
        </div>
      </div>
      <div className="font-tertiary-regular---8pt flex w-full flex-col items-center gap-0.5 text-center">
        <Text tagName="span" className="leading-relaxed text-text-gray">
          {t('settings.form.footer.caption')}
        </Text>
        <a href="mailto:support@thenumberx.com" className="">
          <Text tagName="span">support@thenumberx.com</Text>
        </a>
      </div>
    </div>
  )
}

export { Footer }
