import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { BgWrapper, WrapperType } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import Apis from 'images/home/apis.svg'
import CmsReady from 'images/home/cms_ready.svg'
import Docs from 'images/home/docs.svg'
import LibrarySdk from 'images/home/library_sdk.svg'
import Sdks from 'images/home/sdks.svg'
import Arrow from 'images/icons/arrow2.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Card } from './components/Card/Card'

type TProps = {}

const CARDS = [Apis, Sdks, Docs]
const IMAGES = [
  {
    tKey: 'cms',
    src: CmsReady,
  },
  {
    tKey: 'sdk',
    src: LibrarySdk,
  },
]

const ApiBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <BgWrapper type={WrapperType.LIGHT}>
      <ResponsiveBlock>
        <div className="flex w-full flex-col items-center gap-10 py-20 max-xl:gap-10 max-xl:py-8">
          <div className="flex w-full flex-col items-center gap-5 text-center">
            <Text
              tagName="h2"
              className="font-title---100px uppercase text-palette-black max-1.5xl:font-title---70px max-lg:font-title---50px"
            >
              {t('api_block.title')}
            </Text>
            <Text tagName="span" className="font-body---24px whitespace-pre-wrap text-palette-black">
              {t('api_block.caption')}
            </Text>
            <a
              className="flex items-center gap-2 p-2 hover:no-underline focus:outline-none"
              href="https://docs.thenumberx.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Text tagName="span" className="font-body---20px uppercase text-palette-blue">
                {t('api_block.button')}
              </Text>
              <Arrow className="h-4 w-4" />
            </a>
          </div>
          <div className="grid grid-cols-3 gap-6 max-xl:grid-cols-1">
            {CARDS.map((item, index) => {
              const cardNumber = index + 1

              return (
                <Card
                  key={cardNumber}
                  icon={item}
                  title={t(`api_block.cards.${cardNumber}.title`)}
                  caption={t(`api_block.cards.${cardNumber}.caption`)}
                />
              )
            })}
          </div>
          <div className="grid w-full grid-cols-2 gap-6 max-lg:grid-cols-1">
            {IMAGES.map((item, index) => {
              const cardNumber = index + 1

              return (
                <div key={cardNumber} className="relative flex w-full">
                  <img src={item.src} className="w-full" />
                  <Text tagName="span" className="font-title---32px absolute left-6 top-6 uppercase text-palette-grey">
                    {t(`api_block.${item.tKey}`)}
                  </Text>
                </div>
              )
            })}
          </div>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { ApiBlock }
