import { FC, useEffect, useState } from 'react'
import Icon from 'images/svg/digital_distribution/instagram.svg'
import { motion, useScroll, useTransform } from 'motion/react'

import { ICON_SIZE, SMALL_ORBIT_COEFF } from '../../constants'

type TProps = { width?: number }

const Instagram: FC<TProps> = ({ width = 0 }) => {
  const [size, setSize] = useState<number>(width)

  useEffect(() => {
    setSize(width * SMALL_ORBIT_COEFF)
  }, [width])

  const { scrollYProgress } = useScroll()

  const pDeg = useTransform(scrollYProgress, [0, 0.07, 0.13, 0.2], [-85, -70, -65, -60])
  const cDeg = useTransform(scrollYProgress, [0, 0.07, 0.13, 0.2], [85, 70, 65, 60])

  const translateX = size / 2 - ICON_SIZE / 2
  const translateY = -(ICON_SIZE / 2)

  return (
    <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center overflow-hidden">
      <motion.div style={{ width: size, height: size, rotate: pDeg }}>
        <motion.div
          className="flex h-16 w-16"
          style={{ rotate: cDeg, translateX, translateY }}
          transition={{ duration: 0.3 }}
        >
          <img
            src={Icon}
            className="rounded-half object-contain"
            style={{ boxShadow: '0px 0px 27.7px 0px rgba(243, 120, 55, 0.64)' }}
          />
        </motion.div>
      </motion.div>
    </div>
  )
}

export { Instagram }
