import { FC } from 'react'
import { Text } from '@blueprintjs/core'

type TProps = {
  icon: string
  title: string
  caption: string
}

const Card: FC<TProps> = ({ icon, title, caption }) => {
  return (
    <div className="mx-auto flex w-full flex-col rounded-2.5 bg-palette-black--10 pt-6 max-xl:max-w-[calc(35rem)]">
      <div className="flex justify-end">
        <img src={icon} className="" />
      </div>
      <div className="flex w-full flex-col gap-3 p-6">
        <Text tagName="span" className="font-title---32px text-palette-black">
          {title}
        </Text>
        <Text tagName="span" className="text-palette-black--50 font-body---20px">
          {caption}
        </Text>
      </div>
    </div>
  )
}

export { Card }
