import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'

export enum WrapperType {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export enum WrapperShadow {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

type TProps = {
  className?: string
  type?: WrapperType
  shadow?: WrapperShadow
  hasGradient?: boolean
} & PropsWithChildren

const BgWrapper: FC<TProps> = ({ className = '', type = WrapperType.DARK, shadow, hasGradient, children }) => {
  return (
    <div
      className={cn(
        'relative flex w-full justify-center overflow-hidden px-4',
        {
          'bg-palette-black': type === WrapperType.DARK,
          'bg-palette-grey': type === WrapperType.LIGHT,
          'overflow-hidden': !!shadow,
          '': shadow === WrapperShadow.TOP,
          '': shadow === WrapperShadow.BOTTOM,
        },
        className,
      )}
      style={{
        backgroundImage: hasGradient
          ? `linear-gradient(${shadow === WrapperShadow.BOTTOM ? '180' : 0}deg, #060B17 0%, #021948 150%)`
          : '',
      }}
    >
      {shadow === WrapperShadow.TOP && (
        <div
          className="absolute top-0 flex h-[calc(25vw)] w-full -translate-y-full rounded-half opacity-70"
          style={{
            boxShadow: '0px 0px 500px 100px var(--palette-blue)',
          }}
        />
      )}
      {shadow === WrapperShadow.BOTTOM && (
        <div
          className="absolute top-full flex aspect-square w-full -translate-y-10 rounded-half opacity-70"
          style={{
            // boxShadow: '0px 0px 500px 10px var(--palette-blue)',
            background: 'rgba(0, 98, 255, 0.50)',
            filter: 'blur(176px)',
          }}
        />
      )}
      <div className={cn('flex w-full')}>{children}</div>
    </div>
  )
}

export { BgWrapper }
