import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'
import { SCROLL_APPEAR_INVERSE_VARIANTS, SCROLL_APPEAR_VARIANTS } from 'lib/constants/animations'
import { motion } from 'motion/react'

type TProps = { className?: string; delay?: number; delayChildren?: number; invert?: boolean } & PropsWithChildren

const ScrollAppear: FC<TProps> = ({ className, invert = false, delay = 0.5, delayChildren = 0.8, children }) => {
  return (
    <motion.div
      className={cn('flex', className)}
      whileInView="visible"
      initial={import.meta.env.SSR ? 'visible' : 'hidden'}
      variants={invert ? SCROLL_APPEAR_INVERSE_VARIANTS : SCROLL_APPEAR_VARIANTS}
      viewport={{ once: true }}
      transition={{ type: 'linear', duration: 0.5, delay, delayChildren }}
    >
      {children}
    </motion.div>
  )
}

export { ScrollAppear }
