import { FC, PropsWithChildren } from 'react'
import { Text } from '@blueprintjs/core'

type TProps = {
  title: string
} & PropsWithChildren

const LinksColumn: FC<TProps> = ({ title, children }) => {
  return (
    <section className="flex flex-col gap-8">
      <Text tagName="span" className="font-title---32px uppercase text-text-white">
        {title}
      </Text>
      <ul className="flex flex-col gap-0">{children}</ul>
    </section>
  )
}

export { LinksColumn }
