import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import ctl from '@netlify/classnames-template-literals'
import { BgWrapper } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import TarkovLogo from 'images/home/battle_state.svg'
import Ruby from 'images/home/ruby.svg'
import SlowdiveLogo from 'images/home/slowdive.svg'
import TarkovImage from 'images/home/tarkov.jpg'
import TelegramLogo from 'images/home/telegram.svg'
import TelegramImage from 'images/home/telegram_card.svg'
import SlowdiveImage from 'images/home/waves.jpg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Card } from './components/Card/Card'

type TProps = {}

const CARDS = [
  {
    image: TelegramImage,
    logo: TelegramLogo,
    logoClassName: ctl(`h-8`),
  },
  {
    image: TarkovImage,
    logo: TarkovLogo,
    logoClassName: ctl(`h-13`),
  },
  {
    image: SlowdiveImage,
    logo: SlowdiveLogo,
    logoClassName: ctl(`h-10`),
  },
]

const PartnersBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <BgWrapper hasGradient>
      <ResponsiveBlock>
        <div className="flex w-full flex-col gap-32 py-40 max-1.5xl:py-20 max-lg:gap-12 max-lg:py-8">
          <div className="flex items-center justify-between gap-8 max-lg:flex-col max-lg:text-center">
            <div className="flex items-center gap-8 max-lg:gap-4 max-sm:flex-col">
              <img src={Ruby} className="h-18 max-lg:h-12" />
              <Text
                tagName="h2"
                className="font-title---100px whitespace-nowrap uppercase text-palette-grey max-1.5xl:font-title---70px max-lg:font-title---50px"
              >
                {t('partners_block.title')}
              </Text>
            </div>
            <Text tagName="span" className="font-body---28px text-palette-grey 1.5xl:whitespace-pre-wrap">
              {t('partners_block.caption')}
            </Text>
          </div>
          <div className="grid w-full grid-cols-3 gap-5 max-lg:grid-cols-1">
            {CARDS.map((item, index) => {
              const cardNumber = index + 1

              return (
                <Card
                  key={cardNumber}
                  image={item.image}
                  logo={item.logo}
                  logoClassName={item.logoClassName}
                  tKey={`partners_block.cards.${cardNumber}.title`}
                />
              )
            })}
          </div>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { PartnersBlock }
