import { FC, PropsWithChildren } from 'react'
import { Link } from '@inertiajs/react'
import ctl from '@netlify/classnames-template-literals'

type TProps = {
  href: string
  isExternal?: boolean
} & PropsWithChildren

const linkClassName = ctl(`
  font-body---14px flex select-none flex-nowrap
  p-2 uppercase text-text-white transition-colors
  hover:text-text-secondary hover:no-underline focus:outline-none
`)

const NavLink: FC<TProps> = ({ href, isExternal = false, children }) => {
  return (
    <li className="flex">
      {isExternal ? (
        <a className={linkClassName} href={href} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        <Link className={linkClassName} href={href}>
          {children}
        </Link>
      )}
    </li>
  )
}

export { NavLink }
