import { FC, PropsWithChildren } from 'react'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'
import Pattern from 'images/home/pattern.png'
// import Pattern from 'images/home/pattern2.png'

type TProps = {
  title: string
} & PropsWithChildren

const Card: FC<TProps> = ({ title, children }) => {
  return (
    <div
      className="relative flex aspect-square items-center justify-center overflow-hidden rounded-xl border-1 border-solid border-palette-grey--30 bg-repeat px-14 max-sm:aspect-16/10"
      style={{
        backgroundPositionX: '7px',
        backgroundImage: `url(${Pattern})`,
        boxShadow: 'inset 0px 0px 20px 10px #060b17',
      }}
    >
      <div className="absolute h-36 w-36 bg-palette-blue opacity-60 blur-2xl" />
      {children}
      <div className="absolute left-5 top-5 flex">
        <Text tagName="span" className="font-title---32px uppercase text-palette-white">
          {title}
        </Text>
      </div>
    </div>
  )
}

export { Card }
