import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { BgWrapper } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import { Trans } from 'client/components/Trans/Trans'
import ChatBubble from 'images/home/chat_bubble.svg'
import Controller from 'images/home/controller.svg'
import Elearning from 'images/home/e_learning.svg'
import MobileApps from 'images/home/mobile_apps.svg'
import SubscriptionAdd from 'images/home/money.svg'
import Subscription from 'images/home/subscription.svg'
import VideoGames from 'images/home/video_games.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Card } from './components/Card/Card'

type TProps = {}

const EcosystemBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <BgWrapper>
      <ResponsiveBlock>
        <div className="flex w-full flex-col items-center gap-8 py-20">
          <Text
            tagName="h2"
            className="font-title---100px text-center uppercase text-palette-white max-1.5xl:font-title---70px max-lg:font-title---50px"
          >
            {t('ecosystem_block.title')}
          </Text>
          <Text tagName="span" className="font-body---24px whitespace-pre-wrap text-center text-palette-grey--50">
            <Trans i18nKey="ecosystem_block.caption" ns={ClientLocaleNS.MainPage} />
          </Text>
          <ul className="grid w-full grid-cols-4 gap-8 max-1.5xl:grid-cols-2 max-1.5xl:px-30 max-lg:px-12 max-md:px-0 max-sm:grid-cols-1">
            <li className="relative">
              <Card title={t('ecosystem_block.list.1.title')}>
                <div className="relative translate-y-6">
                  <ScrollAppear delay={0.3}>
                    <img src={Subscription} className="w-full" />
                  </ScrollAppear>
                  <img src={SubscriptionAdd} className="absolute -top-6 left-10" />
                </div>
              </Card>
            </li>
            <li className="relative">
              <Card title={t('ecosystem_block.list.2.title')}>
                <div className="relative translate-y-6">
                  <ScrollAppear delay={0.6}>
                    <img src={VideoGames} className="w-full" />
                  </ScrollAppear>
                  <img src={Controller} className="absolute -left-5 -top-5" />
                </div>
              </Card>
            </li>
            <li className="relative">
              <Card title={t('ecosystem_block.list.3.title')}>
                <div className="relative translate-y-6">
                  <ScrollAppear delay={0}>
                    <img src={Elearning} className="w-full" />
                  </ScrollAppear>
                  <img src={ChatBubble} className="absolute -left-6 -top-2" />
                </div>
              </Card>
            </li>
            <li className="relative">
              <Card title={t('ecosystem_block.list.4.title')}>
                <div className="relative translate-y-6">
                  <ScrollAppear delay={0.9}>
                    <img src={MobileApps} className="w-full" />
                  </ScrollAppear>
                </div>
              </Card>
            </li>
          </ul>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { EcosystemBlock }
