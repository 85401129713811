import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'

type TProps = {
  className?: string
} & PropsWithChildren

const GradientBorder: FC<TProps> = ({ className, children }) => {
  return (
    <div
      className={cn('flex w-1/2 items-center justify-center rounded-xl bg-palette-black text-palette-white', className)}
      style={{
        border: '3px solid transparent',
        borderRadius: '10px',
        backgroundImage: 'linear-gradient(90deg, #032767 0%, #0076FF 50.62%, #032768 100%)',
        backgroundOrigin: 'border-box',
        boxShadow: 'inset 0 500vw #060b17',
      }}
    >
      {children}
    </div>
  )
}

export { GradientBorder }
