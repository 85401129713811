import { FC, ReactNode } from 'react'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'

type TProps = {
  className?: string
  text: string
  icon: string
}

const Tag: FC<TProps> = ({ text, icon, className }) => {
  return (
    <li
      className={cn(
        'flex h-12 flex-1 items-center justify-center gap-2.5 whitespace-nowrap rounded-2.5 px-3',
        className,
      )}
    >
      <img src={icon} className="w-6" />
      <Text tagName="span" className="font-body---16px">
        {text}
      </Text>
    </li>
  )
}

export { Tag }
