import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonGroup } from '@blueprintjs/core'
import { useClaimController } from 'client/controllers/claimController'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const HeaderButtons: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.Common)
  const { setIsOpen } = useClaimController()

  return (
    <ButtonGroup className="items-center gap-5" minimal>
      <a
        className="font-body---14px p-2 font-black uppercase text-text-white outline-none transition-colors hover:text-text-secondary hover:no-underline"
        href="https://dashboard.thenumberx.com/login/"
        target="_blank"
        rel="noreferrer"
      >
        {t('actions.log_in')}
      </a>
      <Button
        className={`
            font-body---14px h-10 rounded-lg 
            !bg-palette-blue px-4 font-black
            uppercase !text-palette-white
            transition-colors
            duration-200
            hover:!bg-surface-tertiary
            hover:!text-text-white
            focus:outline-none
        `}
        onClick={() => setIsOpen(true)}
      >
        {t('actions.open_account')}
      </Button>
    </ButtonGroup>
  )
}

export { HeaderButtons }
