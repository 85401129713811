import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'

type TProps = {
  className?: string
} & PropsWithChildren

const Card: FC<TProps> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'mx-auto flex w-full flex-col rounded-2.5 bg-palette-grey p-6 max-xl:max-w-[calc(35rem)]',
        className,
      )}
    >
      {children}
    </div>
  )
}

export { Card }
