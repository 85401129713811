import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { BgWrapper } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import Planet from 'images/home/planet.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

import { Card } from './components/Card/Card'

type TProps = {}

const BENEFITS = ['-40%', '-85%', '-100%']

const BenefitsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <BgWrapper>
      <ResponsiveBlock>
        <div
          className="mb-30 flex w-full flex-col gap-52 rounded-2.5 bg-palette-blue bg-contain bg-bottom bg-no-repeat px-32 py-20 max-1.5xl:gap-30 max-1.5xl:px-8 max-1.5xl:py-20 max-xl:mb-8 max-xl:gap-12 max-lg:py-8"
          style={{
            backgroundImage: `url(${Planet})`,
          }}
        >
          <Text
            tagName="h2"
            className="font-title---100px text-center uppercase text-palette-black max-1.5xl:font-title---70px max-lg:font-title---50px"
          >
            {t('benefits_block.title')}
          </Text>
          <div className="grid grid-cols-3 gap-8 max-xl:grid-cols-1 1.5xl:mb-10 1.5xl:w-full">
            {BENEFITS.map((item, index) => {
              const listNumber = index + 1

              return (
                <Card key={listNumber}>
                  <div className="flex w-full flex-col gap-12 py-8 text-center">
                    <Text tagName="span" className="font-title---100px text-palette-black max-1.5xl:font-title---70px">
                      {item}
                    </Text>
                    <Text tagName="span" className="font-body---20px text-palette-black--70">
                      {t(`benefits_block.list.${listNumber}.title`)}
                    </Text>
                  </div>
                </Card>
              )
            })}
          </div>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { BenefitsBlock }
