import { Variants } from 'motion/react'

export const SCROLL_APPEAR_VARIANTS: Variants = { hidden: { opacity: 0, y: 32 }, visible: { opacity: 1, y: 0 } }

export const SCROLL_APPEAR_INVERSE_VARIANTS: Variants = {
  hidden: { opacity: 0, y: -32 },
  visible: { opacity: 1, y: 0 },
}

export const PRODUCT_VARIANTS: Variants = { closed: { padding: 0 }, open: { padding: 32 } }

export const HEIGHT_VARIANTS: Variants = { closed: { height: 0 }, open: { height: 'auto' } }
export const OPACITY_VARIANTS: Variants = { hidden: { opacity: 0 }, visible: { opacity: 1 } }
