import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { BgWrapper, WrapperShadow } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import { ScrollAppear } from 'client/components/ScrollAppear/ScrollAppear'
import BattleStatte from 'images/home/brands/battle_state.svg?react'
import Holavpn from 'images/home/brands/hola_vpn.svg?react'
import Legionfarm from 'images/home/brands/legion_farm.svg?react'
import Playrix from 'images/home/brands/playrix.svg?react'
import Riotgames from 'images/home/brands/riot_games.svg?react'
import Telegram from 'images/home/brands/telegram.svg?react'
import Checkout from 'images/home/checkout.svg?react'
import Skew from 'images/home/skew.png'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const BRANDS = [Telegram, Playrix, Holavpn, Legionfarm, Riotgames, BattleStatte]

const MainBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <BgWrapper shadow={WrapperShadow.TOP}>
      <ResponsiveBlock>
        <div className="flex min-h-screen w-full flex-col gap-20 py-40 pb-12 max-1.5xl:gap-30 max-1.5xl:py-20 max-lg:py-8 max-md:gap-12 max-sm:gap-0">
          <div className="flex w-full flex-1 items-center justify-between gap-12 max-1.5xl:flex-col max-1.5xl:pt-40 max-md:gap-0">
            <div className="flex flex-col gap-6 whitespace-pre-wrap max-xl:text-center">
              <Text
                tagName="h1"
                className="font-title---120px uppercase text-palette-white max-xl:font-title---70px max-lg:font-title---50px"
              >
                {t('main_block.title')}
              </Text>
              <Text tagName="span" className="font-body---24px text-palette-grey--50">
                {t('main_block.caption')}
              </Text>
            </div>
            <div className="relative flex pr-16 max-xl:pr-0">
              <div className="-translate-x-1/2 -translate-y-1/4 max-1.5xl:-translate-x-1/4 max-1.5xl:-translate-y-0 max-1.5xl:pb-12 max-md:scale-75 max-sm:-translate-x-10 max-sm:scale-50">
                <ScrollAppear>
                  <img src={Skew} className="" />
                  <Checkout className="absolute -right-30 top-24" />
                </ScrollAppear>
              </div>
            </div>
          </div>
          <div className="flex w-full items-center gap-20 max-xl:flex-col max-xl:gap-6">
            <Text tagName="span" className="font-body---20px whitespace-pre-wrap text-palette-white max-xl:text-center">
              {t('main_block.brands')}
            </Text>
            <div className="relative flex flex-1">
              <div
                className="absolute -left-8 h-full w-40 max-xl:hidden"
                style={{
                  background: 'linear-gradient(269deg, rgba(6, 11, 23, 0.00) 4.92%, #060B17 86.35%)',
                }}
              />
              <ul className="flex w-full flex-wrap items-center justify-between gap-8 max-lg:justify-center">
                {BRANDS.map((Brand, index) => (
                  <li key={index}>
                    <Brand />
                  </li>
                ))}
              </ul>
              <div
                className="absolute -right-8 h-full w-40 max-xl:hidden"
                style={{
                  background: 'linear-gradient(89deg, rgba(6, 11, 23, 0.00) 4.92%, #060B17 86.35%)',
                }}
              />
            </div>
          </div>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { MainBlock }
