import { FC } from 'react'
import { Trans as OriginalTrans, useTranslation } from 'react-i18next'
import ctl from '@netlify/classnames-template-literals'
import { TOptions } from 'i18next'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {
  ns?: ClientLocaleNS
  i18nKey: string
  components?: readonly React.ReactElement[] | { readonly [tagName: string]: React.ReactElement }
  values?: TOptions
}

// tailwind trans bug
export const transColors = [ctl(`text-palette-blue`), ctl(`text-palette-violet`), ctl(`text-palette-green`)]

const Trans: FC<TProps> = ({ ns = ClientLocaleNS.Common, i18nKey, components, values }) => {
  const { t } = useTranslation(ns)

  return (
    <OriginalTrans
      t={t}
      i18nKey={i18nKey}
      components={{
        span: <span />,
        strong: <strong />,
        br: <br />,
        ...components,
      }}
      values={values}
    />
  )
}

export { Trans }
