import { FC } from 'react'
import cn from 'classnames'
import AppLogo from 'images/svg/logo.svg?react'

type TProps = {
  className?: string
}

const Logo: FC<TProps> = ({ className = 'h-full' }) => {
  return (
    <div className={cn('flex flex-none', className)}>
      <AppLogo />
    </div>
  )
}

export { Logo }
