import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { GradientBorder } from 'client/components/GradientBorder/GradientBorder'
import { BgWrapper } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import { Logo } from 'components/Logo/Logo'
import Arrow from 'images/icons/arrow2.svg?react'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const WelcomeBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <BgWrapper>
      <ResponsiveBlock className="py-12">
        <GradientBorder className="w-full">
          <div className="flex w-full flex-col justify-center gap-8 px-60 py-36 max-1.5xl:px-20 max-lg:px-8 max-lg:py-12">
            <div className="flex items-center justify-center gap-3">
              <Text tagName="span" className="font-body---20px whitespace-nowrap uppercase">
                {t('welcome_block.caption')}
              </Text>
              <Logo className="h-3.5" />
            </div>
            <Text
              tagName="h2"
              className="font-title---100px whitespace-pre-wrap text-center uppercase text-palette-white max-1.5xl:font-title---70px max-lg:font-title---50px"
            >
              {t('welcome_block.title')}
            </Text>
            <Text
              tagName="h2"
              className="font-title---100px mb-12 whitespace-pre-wrap text-center uppercase text-palette-blue max-1.5xl:font-title---70px max-lg:font-title---50px"
            >
              {t('welcome_block.title_add')}
            </Text>
            <ul className="grid grid-cols-2 gap-20 max-lg:grid-cols-1 max-lg:gap-8">
              {Array(2)
                .fill(null)
                .map((_, index) => (
                  <li key={index} className="inline-block">
                    <Arrow className="mr-3 h-4 w-4 text-palette-blue" />
                    <Text tagName="span" className="font-body---20px inline text-palette-grey">
                      {t(`welcome_block.list.${index + 1}.title`)}
                    </Text>
                  </li>
                ))}
            </ul>
          </div>
        </GradientBorder>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { WelcomeBlock }
