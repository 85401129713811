import { FC } from 'react'
import { Button } from '@blueprintjs/core'
import { Menu } from '@blueprintjs/icons'
import { Link } from '@inertiajs/react'
import { Logo } from 'components/Logo/Logo'

import { useMenuDrawerController } from '../MenuDrawer/controller/menuDrawerController'
import { ResponsiveBlock } from '../ResponsiveBlock/ResponsiveBlock'

import { HeaderButtons } from './components/HeaderButtons/HeaderButtons'
import { Navigation } from './components/Navigation/Navigation'

type TProps = {}

const Header: FC<TProps> = () => {
  const controller = useMenuDrawerController()

  return (
    <header className="absolute top-8 z-1 flex h-19 w-full flex-none items-center justify-center">
      <ResponsiveBlock className="px-4">
        <div className="flex h-19 w-full items-center justify-between gap-4 rounded-xl bg-palette-black px-6">
          <Link href="/" className="h-6 outline-none">
            <Logo className="h-6" />
          </Link>
          <div className="hidden h-full items-center 1.5xl:flex">
            <Navigation />
          </div>
          <div className="hidden h-full items-center 1.5xl:flex">
            <HeaderButtons />
          </div>
          <div className="flex 1.5xl:hidden">
            <Button
              className="h-12 w-12 rounded-half !bg-surface-black shadow-blockBlue focus:outline-none"
              icon={<Menu className="!text-text-white" size={20} />}
              minimal
              onClick={controller.open}
            />
          </div>
        </div>
      </ResponsiveBlock>
    </header>
  )
}

export { Header }
