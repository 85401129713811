import { FC, ReactNode } from 'react'
import { PaymentType, usePaymentController } from 'client/controllers/paymentsController'
import OneTime from 'images/demo/one_time.png'
import Subscription from 'images/demo/subscription.png'
import { AnimatePresence, motion } from 'motion/react'

import { OneTimeContent } from './components/OneTimeContent/OneTimeContent'
import { PlanLayout } from './components/PlanLayout/PlanLayout'
import { SubscriptionContent } from './components/SubscriptionContent/SubscriptionContent'

type TProps = {}

const PAYMENT_TYPE_CONTENT_MAP: { [key in PaymentType]: { image: string; content: ReactNode } } = {
  [PaymentType.ONE_TIME]: { image: OneTime, content: <OneTimeContent /> },
  [PaymentType.SUBSCRIPTION]: { image: Subscription, content: <SubscriptionContent /> },
}

const PlanContent: FC<TProps> = () => {
  const { settings } = usePaymentController()
  const item = PAYMENT_TYPE_CONTENT_MAP[settings.paymentType]

  return (
    <div className="pt-20">
      <AnimatePresence>
        <motion.div
          key={item.image}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="absolute flex flex-1 -translate-x-1/2"
        >
          <PlanLayout image={item.image}>{item.content}</PlanLayout>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export { PlanContent }
