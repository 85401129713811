import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@blueprintjs/core'
import { BgWrapper, WrapperType } from 'client/components/LayoutNew/components/BgWrapper/BgWrapper'
import { ResponsiveBlock } from 'client/components/LayoutNew/components/ResponsiveBlock/ResponsiveBlock'
import { Trans } from 'client/components/Trans/Trans'
import Braintree from 'images/home/braintree.svg'
import Square from 'images/home/square.svg'
import Stripe from 'images/home/stripe.svg'
import Check from 'images/icons/check_circle.svg?react'
import Logo from 'images/svg/logo_black.svg'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {}

const ProcessorsBlock: FC<TProps> = () => {
  const { t } = useTranslation(ClientLocaleNS.MainPage)

  return (
    <BgWrapper type={WrapperType.LIGHT}>
      <ResponsiveBlock>
        <div className="flex w-full gap-20 py-30 max-1.5xl:flex-col max-xl:gap-12 max-xl:py-8">
          <div className="flex w-5/12 flex-col gap-6 max-1.5xl:w-full max-1.5xl:items-center max-1.5xl:text-center">
            <Text tagName="span" className="font-body---14px uppercase text-palette-blue">
              {t('processors_block.subtitle')}
            </Text>
            <Text
              tagName="h2"
              className="font-title---100px uppercase text-palette-black max-1.5xl:font-title---70px max-lg:font-title---50px"
            >
              {t('processors_block.title')}
            </Text>
            <Text tagName="p" className="font-body---20px whitespace-pre-wrap text-palette-black--70">
              <Trans i18nKey={'processors_block.caption'} ns={ClientLocaleNS.MainPage} />
            </Text>
          </div>
          <div className="flex flex-1 flex-col gap-6">
            <div className="grid grid-cols-3 gap-3">
              <div className="col-span-2 flex h-5 w-full items-center gap-2 max-sm:h-auto max-sm:flex-wrap max-sm:py-2">
                <img src={Braintree} className="flex h-full -translate-y-0.5 max-lg:h-auto" />
                <div className="h-5 w-px bg-palette-black--30" />
                <img src={Stripe} className="h-full max-lg:h-auto" />
                <div className="h-5 w-px bg-palette-black--30" />
                <img src={Square} className="h-full max-lg:h-auto" />
                <Text tagName="span" className="font-body---16px text-palette-red 1.5xl:whitespace-nowrap">
                  {t('processors_block.rates')}
                </Text>
              </div>
              <div className="col-span-1 flex h-5 w-full justify-center max-sm:h-auto">
                <img src={Logo} className="h-full max-xl:w-full " />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-3">
              {Array(3)
                .fill(null)
                .map((_, columnIndex) => {
                  const columnNumber = columnIndex + 1

                  return (
                    <ul key={columnNumber} className="flex w-full flex-col gap-3">
                      {Array(6)
                        .fill(null)
                        .map((_, listIndex) => {
                          const listNumber = listIndex + 1

                          return (
                            <li
                              key={listNumber}
                              className="flex h-20 w-full rounded-2.5 bg-palette-black--10 max-lg:h-30 max-sm:h-40"
                            >
                              {columnNumber === 3 ? (
                                listNumber === 1 ? (
                                  <div className="flex h-full w-full flex-col items-center justify-center p-4 text-center">
                                    <Text tagName="span" className="font-body---16px text-palette-black">
                                      {t(`processors_block.columns.${columnNumber}.list.${listNumber}.title`)}
                                    </Text>
                                    <Text tagName="span" className="font-body---12px text-palette-black--50">
                                      {t(`processors_block.columns.${columnNumber}.list.${listNumber}.caption`)}
                                    </Text>
                                  </div>
                                ) : (
                                  <div className="flex flex-1 items-center justify-center">
                                    <Check className="h-6 w-6 text-palette-blue" />
                                  </div>
                                )
                              ) : (
                                <div className="flex h-full w-full flex-col items-center justify-center p-4 text-center">
                                  <Text tagName="span" className="font-body---12px text-palette-black--50">
                                    <Trans
                                      i18nKey={`processors_block.columns.${columnNumber}.list.${listNumber}.title`}
                                      ns={ClientLocaleNS.MainPage}
                                    />
                                  </Text>
                                  <Text
                                    tagName="span"
                                    className="font-body---16px whitespace-pre-wrap text-palette-black"
                                  >
                                    <Trans
                                      i18nKey={`processors_block.columns.${columnNumber}.list.${listNumber}.caption`}
                                      ns={ClientLocaleNS.MainPage}
                                    />
                                  </Text>
                                </div>
                              )}
                            </li>
                          )
                        })}
                    </ul>
                  )
                })}
            </div>
            <div className="flex">
              <Text tagName="span" className="font-body---16px text-palette-black 1.5xl:whitespace-nowrap">
                {t('processors_block.fees')}
              </Text>
            </div>
            <div className="grid grid-cols-3 gap-3">
              {Array(3)
                .fill(null)
                .map((_, columnIndex) => {
                  const columnNumber = columnIndex + 1

                  return (
                    <ul key={columnNumber} className="flex w-full flex-col gap-3">
                      {Array(2)
                        .fill(null)
                        .map((_, listIndex) => {
                          const listNumber = listIndex + 1

                          return (
                            <li
                              key={listNumber}
                              className="flex h-20 w-full rounded-2.5 bg-palette-black--10 max-lg:h-30 max-sm:h-40"
                            >
                              {columnNumber === 3 ? (
                                <div className="flex flex-1 items-center justify-center">
                                  <Check className="h-6 w-6 text-palette-blue" />
                                </div>
                              ) : (
                                <div className="flex h-full w-full flex-col items-center justify-center p-4 text-center">
                                  <Text tagName="span" className="font-body---12px text-palette-black--50">
                                    <Trans
                                      i18nKey={`processors_block.fees_columns.${columnNumber}.list.${listNumber}.title`}
                                      ns={ClientLocaleNS.MainPage}
                                    />
                                  </Text>
                                </div>
                              )}
                            </li>
                          )
                        })}
                    </ul>
                  )
                })}
            </div>
          </div>
        </div>
      </ResponsiveBlock>
    </BgWrapper>
  )
}

export { ProcessorsBlock }
