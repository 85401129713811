import { FC, PropsWithChildren } from 'react'
import cn from 'classnames'

type TProps = {
  className?: string
} & PropsWithChildren

const ResponsiveBlock: FC<TProps> = ({ className = '', children }) => {
  return (
    <div className={cn('flex w-full justify-center', className)}>
      <section className={cn('flex w-full max-w-9xl flex-col items-center')}>{children}</section>
    </div>
  )
}

export { ResponsiveBlock }
