import { FC } from 'react'
import { Text } from '@blueprintjs/core'
import cn from 'classnames'
import { Trans } from 'client/components/Trans/Trans'
import { ClientLocaleNS } from 'lib/constants/locales'

type TProps = {
  image: string
  logo: string
  logoClassName: string
  tKey: string
}

const Card: FC<TProps> = ({ image, logo, tKey, logoClassName }) => {
  return (
    <div className="flex w-full flex-col gap-10">
      <div
        className="flex aspect-16/10 w-full rounded-2.5 bg-cover bg-center p-5"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <img src={logo} className={cn('', logoClassName)} />
      </div>
      <div className="flex w-full">
        <Text tagName="p" className="font-body---20px text-palette-grey--50">
          <Trans i18nKey={tKey} ns={ClientLocaleNS.MainPage} />
        </Text>
      </div>
    </div>
  )
}

export { Card }
